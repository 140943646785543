<template>
  <div class="wrapper">
    <div class="section section-blogs-1">
      <!-- Blogs 1 -->
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-80 md-small-size-100 mx-auto">
            <h2 class="title">
              {{ $t('home.features.title') }}
            </h2>
            <h3 class="subtitle">
              {{ $t('home.features.subtitle') }}
            </h3>
            <br />
            <blog-card
              card-plain
              type="horizontal"
              :shadow-normal="false"
              :no-colored-shadow="false"
              :card-image="cardBlogPost1"
            >
              <template slot="cardContent">
                <h6 class="card-category text-primary">
                  <md-icon class="text-primary">
                    trending_up
                  </md-icon>
                  {{ $t('home.features.seo_title') }}
                </h6>
                <h3 class="card-title">
                  <a
                    href="javascript:void(0)"
                    @click="$router.push(localePath('features'))"
                    >{{ $t('home.features.seo_sub_title') }}</a
                  >
                </h3>
                <div
                  class="card-description"
                  v-html="$t('home.features.seo_desc')"
                ></div>
                <p class="author">
                  <a
                    href="javascript:void(0)"
                    @click="$router.push(localePath('features'))"
                  >
                    {{ $t('home.features.more') }}
                  </a>
                </p>
              </template>
            </blog-card>
            <blog-card
              card-plain
              type="horizontal"
              reverse-row
              :shadow-normal="false"
              :no-colored-shadow="false"
              :card-image="cardBlogPost2"
            >
              <template slot="cardContent">
                <h6 class="card-category text-primary">
                  <md-icon class="text-primary">
                    dashboard
                  </md-icon>
                  {{ $t('home.features.management_title') }}
                </h6>
                <h3 class="card-title">
                  <a
                    href="javascript:void(0)"
                    @click="$router.push(localePath('features'))"
                  >
                    {{ $t('home.features.management_sub_title') }}
                  </a>
                </h3>
                <div
                  class="card-description"
                  v-html="$t('home.features.management_desc')"
                ></div>
                <p class="author">
                  <a
                    href="javascript:void(0)"
                    @click="$router.push(localePath('features'))"
                  >
                    {{ $t('home.features.more') }}
                  </a>
                </p>
              </template>
            </blog-card>
            <blog-card
              card-plain
              type="horizontal"
              :shadow-normal="false"
              :no-colored-shadow="false"
              :card-image="cardBlogPost3"
            >
              <template slot="cardContent">
                <h6 class="card-category text-primary">
                  <md-icon class="text-primary">
                    public
                  </md-icon>
                  {{ $t('home.features.website_creation_title') }}
                </h6>
                <h3 class="card-title">
                  <a
                    href="javascript:void(0)"
                    @click="$router.push(localePath('features'))"
                    >{{ $t('home.features.website_creation_sub_title') }}</a
                  >
                </h3>
                <div class="card-description">
                  {{ $t('home.features.website_creation_desc') }}
                </div>
                <p class="author">
                  <a
                    href="javascript:void(0)"
                    @click="$router.push(localePath('features'))"
                  >
                    {{ $t('home.features.more') }}
                  </a>
                </p>
              </template>
            </blog-card>
            <blog-card
              card-plain
              type="horizontal"
              reverse-row
              :shadow-normal="false"
              :no-colored-shadow="false"
              :card-image="cardBlogPost4"
            >
              <template slot="cardContent">
                <h6 class="card-category text-primary">
                  <md-icon class="text-primary">
                    web
                  </md-icon>
                  {{ $t('home.features.iframe_title') }}
                </h6>
                <h3 class="card-title">
                  <a
                    href="javascript:void(0)"
                    @click="$router.push(localePath('features'))"
                  >
                    {{ $t('home.features.iframe_sub_title') }}
                  </a>
                </h3>
                <div
                  class="card-description"
                  v-html="$t('home.features.iframe_desc')"
                ></div>
                <p class="author">
                  <a
                    href="javascript:void(0)"
                    @click="$router.push(localePath('features'))"
                  >
                    {{ $t('home.features.more') }}
                  </a>
                </p>
              </template>
            </blog-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlogCard from '@/components/creativeTim/kitPro/components/cards/BlogCard.vue'
export default {
  name: 'Blogs',
  components: {
    BlogCard
  },
  data() {
    return {
      cardBlogPost1: require('@/static/img/public/goyoga-manager-referencer-activite.jpeg'),
      cardBlogPost2: require('@/static/img/public/goyoga-manager-simplifier-gestion-activite.jpeg'),
      cardBlogPost3: require('@/static/img/public/goyoga-manager-creer-site-web-reservation.jpeg'),
      cardBlogPost4: require('@/static/img/public/goyoga-manager-plugin-reservation.jpeg')
    }
  }
}
</script>

<style lang="scss" scoped=""></style>
