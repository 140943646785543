<template>
  <div>
    <Header />
    <div id="main-panel" class="main">
      <Features />
      <JoinTeam />
    </div>
  </div>
</template>

<script>
import Header from '~/components/public/home/Header.vue'
import Features from '~/components/public/home/Features.vue'
import JoinTeam from '~/components/public/home/JoinTeam.vue'

export default {
  auth: false, // This allows anonymous user to access this page
  components: {
    Header,
    Features,
    JoinTeam
  },
  data() {
    return {}
  },
  beforeCreate() {}
}
</script>

<style></style>
