<template>
  <div class="wrapper">
    <div class="section section-header-2 pt-0 pb-0">
      <div class="page-header header-filter" :style="parallaxStyle">
        <div class="container">
          <div class="md-layout">
            <div
              class="md-layout-item md-size-66 md-small-size-100 md-xlarge-size-80 mx-auto text-center"
            >
              <h1 class="title">
                {{ $t('home.join_team.title') }}
              </h1>
              <h4 v-html="$t('home.join_team.sub_title')"></h4>
              <md-button
                class="md-success"
                @click="$router.push(localePath('/contact'))"
              >
                {{ $t('home.join_team.contact_us') }}
              </md-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Headers',
  components: {},
  data() {
    return {
      sliderImg: require('@/static/img/public/goyoga-manager-blog-team.jpeg')
    }
  },
  computed: {
    parallaxStyle() {
      return 'backgroundImage: url(' + this.sliderImg + ')'
    }
  },
  watch: {},
  beforeCreate() {},
  created() {},
  mounted() {},
  methods: {}
}
</script>
