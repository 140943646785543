<template>
  <div class="page-header header-filter" :style="header('1')">
    <div class="container">
      <div class="md-layout">
        <div
          class="md-layout-item md-size-100 md-small-size-100 ml-auto text-right"
        >
          <h1 class="title">
            {{ $t('header.public_home_title') }}
          </h1>
          <h4>
            {{ $t('header.public_home_subtitle') }}
          </h4>
          <h4>
            {{ $t('header.public_home_subtitle2') }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'

export default {
  name: 'Headers',
  components: {},
  data() {
    return {
      headerImages: {
        Image1: require('@/static/img/public/goyoga-manager-nouveau-paradigme.jpeg')
      }
    }
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {},
  mounted() {},
  methods: {
    ...Vuex.mapMutations(['setSignupModal']),
    header(nr) {
      return {
        backgroundImage: `url(${this.headerImages[`Image${nr}`]})`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  align-items: center;
}

#headers {
  .el-carousel__container {
    height: 100vh !important;
  }
  .el-carousel {
    border-radius: 0 !important;
  }
}

.text-right {
  text-align: right;
}

.border-radius-0 {
  border-radius: 0;
}

.pb-0 {
  padding-bottom: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}

@media all and (max-width: 960px) {
  .md-card-form-horizontal .md-layout-item {
    margin-top: 1.5rem;
  }
}
</style>
